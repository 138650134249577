
/**
 * Override animate.css here
 */

@keyframes fadeInUpSmall {
    from {
        opacity: 0;
        transform: translate3d(0, 25%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUpSmall {
    animation-name: fadeInUpSmall;
}
